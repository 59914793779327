<template>
  <div class="content">
    <div class="aside">
      <div class="title">订单列表</div>
      <ul class="nev-menu-ul">
<!--        <router-link to="/PersonalCenter/PrintOrder" tag="li">-->
<!--          <div class="item-content">-->
<!--            <div class="pic">-->
<!--              <img src="@/assets/img/shoppingcart.png" />-->
<!--            </div>-->
<!--            <div class="name">印刷订单</div>-->
<!--          </div>-->
<!--        </router-link>-->
        <router-link to="/PersonalCenter/OrderForMe" tag="li">
          <div class="item-content">
            <div class="pic">
              <img src="@/assets/img/shoppingcart.png" />
            </div>
            <div class="name">帮我下单</div>
          </div>
        </router-link>
        <router-link to="/PersonalCenter/PrizeOrder" tag="li">
          <div class="item-content">
            <div class="pic">
              <img src="@/assets/img/shoppingcart.png" />
            </div>
            <div class="name">奖品订单</div>
          </div>
        </router-link>
        <router-link to="/PersonalCenter/TemplateOrder" tag="li">
          <div class="item-content">
            <div class="pic">
              <img src="@/assets/img/shoppingcart.png" />
            </div>
            <div class="name">模板订单</div>
          </div>
        </router-link>
      </ul>
      <div style="height: 80px"></div>
      <div class="title">基础信息</div>
      <ul class="nev-menu-ul">
        <router-link to="/PersonalCenter/AccountInfo" tag="li">
          <div class="item-content">
            <div class="pic">
              <img src="@/assets/img/shoppingcart.png" />
            </div>
            <div class="name">账号信息</div>
          </div>
        </router-link>
        <router-link
          to="/PersonalCenter/CompanyInfo"
          tag="li"
          v-if="roleIds.includes(2)"
        >
          <div class="item-content">
            <div class="pic">
              <img src="@/assets/img/shoppingcart.png" />
            </div>
            <div class="name">企业信息</div>
          </div>
        </router-link>
        <router-link to="/PersonalCenter/ReceivingAddress" tag="li">
          <div class="item-content">
            <div class="pic">
              <img src="@/assets/img/shoppingcart.png" />
            </div>
            <div class="name">收货地址</div>
          </div>
        </router-link>
        <router-link to="/PersonalCenter/InvioceInfo" tag="li">
          <div class="item-content">
            <div class="pic">
              <img src="@/assets/img/shoppingcart.png" />
            </div>
            <div class="name">发票信息</div>
          </div>
        </router-link>
      </ul>
    </div>
    <div class="main">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>

import NevMenuHome from '@/components/NevMenuHome.vue'
import { getRoleIds } from '@/lib/util.js'

export default {
  data () {
    return {
      roleIds: []

    }
  },
  components: {
    // eslint-disable-next-line vue/no-unused-components
    NevMenuHome
  },
  created () {
    this.roleIds = getRoleIds().roleIds
  }

}
</script>

<style lang="scss" scoped>
.nev-menu-ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  .router-link-active {
    background-color: #e7e7ef;
    position: relative;
  }
  .router-link-active::after {
    content: " ";
    display: block;
    width: 5px;
    height: 30px;
    background: black;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
  }
  li {
    height: 46px;
    line-height: 46px;
    display: flex;
    justify-content: center;

    .item-content {
      width: 218px;
      height: 46px;
      display: flex;
      justify-content: center;
      cursor: pointer;
      &:hover {
        background-color: #f1f2f4;
      }
      .pic {
        width: 20%;
      }
      .name {
        width: 60%;
        text-align: left;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #33383e;
      }
    }
  }
}

.content {
  width: 100%;

  margin: auto;
  position: absolute;
  top: 80px;
  bottom: 0;
  background-color: rgba(238, 238, 238, 0.699);
  .aside {
    width: 250px;
    height: 100%;
    padding-top: 15px;
    float: left;
    background-color: white;
    position: relative;
    .title {
      padding-left: 32px;
      font-size: 12px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #939ba6;
      margin-bottom: 10px;
    }
    .space {
      height: 1px;
      background: rgb(218, 218, 218);
      width: 166px;

      margin: 30px 0 30px 30px;
    }
  }
  .main {
    float: right;
    // overflow-y: scroll;
    overflow-x: hidden;
    position: absolute;
    right: 0;
    left: 280px;
    height: 100%;
    //  background-color: white;
  }
}
</style>
