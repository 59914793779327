<template>
  <ul class="nev-menu-ul">
    <li class="nev-menu-li" v-for="(item, index) in nevdata" :key="index">
      <div class="item-content">
        <div class="pic">
          <img :src="item.img" />
        </div>
        <div class="name">
          {{ item.name }}
        </div>
      </div>
    </li>
  </ul>
</template>

<script>

export default {
  props: {
    nevdata: {
      type: Array,
      default: []
    }
  },
  data () {
    return {
      url: require('../assets/img/shoppingcart.png')
    }
  },
  mounted () {
    console.log(require('../assets/img/shoppingcart.png'))
  }

}
</script>

<style lang="scss" scoped>
.nev-menu-ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  .nev-menu-li {
    height: 46px;
    line-height: 46px;
    display: flex;
    justify-content: center;

    .item-content {
      width: 218px;
      height: 46px;
      display: flex;
      justify-content: center;
      &:hover {
        background-color: #f1f2f4;
      }
      .pic {
        width: 20%;
      }
      .name {
        width: 60%;
        text-align: left;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #33383e;
      }
    }
  }
}
</style>
