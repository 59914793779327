var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ul",
    { staticClass: "nev-menu-ul" },
    _vm._l(_vm.nevdata, function(item, index) {
      return _c("li", { key: index, staticClass: "nev-menu-li" }, [
        _c("div", { staticClass: "item-content" }, [
          _c("div", { staticClass: "pic" }, [
            _c("img", { attrs: { src: item.img } })
          ]),
          _c("div", { staticClass: "name" }, [
            _vm._v(" " + _vm._s(item.name) + " ")
          ])
        ])
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }