var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "content" }, [
    _c("div", { staticClass: "aside" }, [
      _c("div", { staticClass: "title" }, [_vm._v("订单列表")]),
      _c(
        "ul",
        { staticClass: "nev-menu-ul" },
        [
          _c(
            "router-link",
            { attrs: { to: "/PersonalCenter/OrderForMe", tag: "li" } },
            [
              _c("div", { staticClass: "item-content" }, [
                _c("div", { staticClass: "pic" }, [
                  _c("img", {
                    attrs: { src: require("@/assets/img/shoppingcart.png") }
                  })
                ]),
                _c("div", { staticClass: "name" }, [_vm._v("帮我下单")])
              ])
            ]
          ),
          _c(
            "router-link",
            { attrs: { to: "/PersonalCenter/PrizeOrder", tag: "li" } },
            [
              _c("div", { staticClass: "item-content" }, [
                _c("div", { staticClass: "pic" }, [
                  _c("img", {
                    attrs: { src: require("@/assets/img/shoppingcart.png") }
                  })
                ]),
                _c("div", { staticClass: "name" }, [_vm._v("奖品订单")])
              ])
            ]
          ),
          _c(
            "router-link",
            { attrs: { to: "/PersonalCenter/TemplateOrder", tag: "li" } },
            [
              _c("div", { staticClass: "item-content" }, [
                _c("div", { staticClass: "pic" }, [
                  _c("img", {
                    attrs: { src: require("@/assets/img/shoppingcart.png") }
                  })
                ]),
                _c("div", { staticClass: "name" }, [_vm._v("模板订单")])
              ])
            ]
          )
        ],
        1
      ),
      _c("div", { staticStyle: { height: "80px" } }),
      _c("div", { staticClass: "title" }, [_vm._v("基础信息")]),
      _c(
        "ul",
        { staticClass: "nev-menu-ul" },
        [
          _c(
            "router-link",
            { attrs: { to: "/PersonalCenter/AccountInfo", tag: "li" } },
            [
              _c("div", { staticClass: "item-content" }, [
                _c("div", { staticClass: "pic" }, [
                  _c("img", {
                    attrs: { src: require("@/assets/img/shoppingcart.png") }
                  })
                ]),
                _c("div", { staticClass: "name" }, [_vm._v("账号信息")])
              ])
            ]
          ),
          _vm.roleIds.includes(2)
            ? _c(
                "router-link",
                { attrs: { to: "/PersonalCenter/CompanyInfo", tag: "li" } },
                [
                  _c("div", { staticClass: "item-content" }, [
                    _c("div", { staticClass: "pic" }, [
                      _c("img", {
                        attrs: { src: require("@/assets/img/shoppingcart.png") }
                      })
                    ]),
                    _c("div", { staticClass: "name" }, [_vm._v("企业信息")])
                  ])
                ]
              )
            : _vm._e(),
          _c(
            "router-link",
            { attrs: { to: "/PersonalCenter/ReceivingAddress", tag: "li" } },
            [
              _c("div", { staticClass: "item-content" }, [
                _c("div", { staticClass: "pic" }, [
                  _c("img", {
                    attrs: { src: require("@/assets/img/shoppingcart.png") }
                  })
                ]),
                _c("div", { staticClass: "name" }, [_vm._v("收货地址")])
              ])
            ]
          ),
          _c(
            "router-link",
            { attrs: { to: "/PersonalCenter/InvioceInfo", tag: "li" } },
            [
              _c("div", { staticClass: "item-content" }, [
                _c("div", { staticClass: "pic" }, [
                  _c("img", {
                    attrs: { src: require("@/assets/img/shoppingcart.png") }
                  })
                ]),
                _c("div", { staticClass: "name" }, [_vm._v("发票信息")])
              ])
            ]
          )
        ],
        1
      )
    ]),
    _c("div", { staticClass: "main" }, [_c("router-view")], 1)
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }